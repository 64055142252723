<template>
  <div class="database">
    <div class="searchBox">
      <el-select
        class="select-search"
        v-model="selectValue"
        placeholder="请选择"
      >
        <el-option
          v-for="item in tabList"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        :placeholder="i18nData.placehold"
        v-model="search"
        class="input-search"
        @keyup.enter.native="goSearch"
      >
        <el-button
          class="button-search"
          slot="append"
          icon="el-icon-search"
          @click="goSearch"
        ></el-button>
      </el-input>
    </div>
    <div style="width:100%;padding: 0 50px;box-sizing: border-box;">
      <el-table
        :data="tableData"
        style="width: 100%;"
        :header-row-style="{ color: '#282728', 'text-align': 'right' }"
        empty-text=" "
        height="50"
      >
        <el-table-column prop="date" label="中文名称"> </el-table-column>
        <el-table-column prop="name" label="英文名称"> </el-table-column>
        <el-table-column prop="address" label="CAS号"> </el-table-column>
        <el-table-column prop="address" label="英文缩写"> </el-table-column>
        <el-table-column prop="address" label="分子式"> </el-table-column>
        <el-table-column prop="address" label="药剂学类别"> </el-table-column>
      </el-table>
    </div>
    <div class="databaseBox">
      <div class="searchNone" v-show="isnone">
        <img src="./../../public/img/search/search_none.png" alt="" />
        <span>{{ i18nData.nonetips }}</span>
      </div>
      <!-- <div class="interest" v-show="isnone">
        <div>{{ i18nData.interest }}</div>
        <div></div>
      </div> -->
      <databaseList :archives-list="list"></databaseList>
    </div>
    <div class="pagination" v-if="archivesList.length > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import databaseList from "./../components/databaseList.vue";
import { toTop } from "./../common/top.mixin.js";

export default {
  name: "search",
  mixins: [toTop],
  components: {
    databaseList
  },
  data() {
    return {
      tableData: [],
      search: "",
      total: 100,
      pageSize: 10,
      currentPage: 1,
      archivesList: [],
      channel: {},
      insterestList: [], //最感兴趣
      isnone: false,
      selectValue: "",
      tabList: [
        {
          id: 24,
          title: "其他",
          seotitle: "Other",
          items: 0,
          image: "https://admin.pku-epquangeno.com/assets/addons/cms/img/noimage.jpg",
          archivesList: []
        },
        {
          id: 6,
          title: "多环芳烃",
          seotitle: "PAHs",
          items: 8,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/592d14cfeaa2ea69c46088786ecf14ee.png",
          archivesList: []
        },
        {
          id: 5,
          title: "药物及个人护理品",
          seotitle: "PPCPs",
          items: 7,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/fdc291ead730e1513b3f8f3cc79f5f26.png",
          archivesList: []
        },
        {
          id: 4,
          title: "全氟辛酸类",
          seotitle: "PFASs",
          items: 5,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/a05dc8ab6dadc5ec62d07ac1132962a6.png",
          archivesList: []
        },
        {
          id: 3,
          title: "挥发性有机污染物",
          seotitle: "VOCs",
          items: 5,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/69eeaeaff3c15781c8536d600e81d2ba.png",
          archivesList: []
        }
      ]
    };
  },
  computed: {
    list() {
      if (this.archivesList.length > 0) {
        return this.archivesList;
      }
      return this.insterestList;
    }
  },
  created() {
    this.$store.state.activeIndex = "";
    let query = this.$route.query;
    if (query.search) {
      this.search = query.search;
    }
    if (query.selectValue) {
      console.log(query.selectValue);
      this.selectValue = Number(query.selectValue);
    }
    this.getCategory();
    this.goSearch();
  },
  methods: {
    // 分页处理
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.goSearch();
      this.backTop();
    },
    async goSearch() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: this.currentPage,
          orderby: "default",
          orderway: "asc",
          q: this.search,
          cid: this.selectValue
        };
        let res = await this.$api.https.search(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { pageList, channel } = res.data;
        this.channel = channel;
        this.total = pageList.total;
        this.archivesList = [...pageList.data];
        if (!(this.archivesList.length > 0)) {
          // this.getInsterest();
          this.isnone = true;
        } else {
          this.isnone = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInsterest() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: 1,
          model: 2,
          orderby: "view"
        };
        let res = await this.$api.https.getArchives(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { pageList } = res.data;
        this.insterestList = [...pageList.data];
      } catch (err) {
        console.log(err);
      }
    },
    async getCategory() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          channel: "",
          menu_index: ""
        };
        let res = await this.$api.https.getCategory(params);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        if (res.code == 1) {
          const tabList = res.data.list;
          tabList.sort((a, b) => (a.id > b.id ? 1 : -1));
          this.tabList = tabList;
        } else {
          this.$message({
            message: res.msg
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-button {
  color: #282828 !important;
}
@mixin interest($width) {
  .interest {
    text-align: center;
    height: 40px;
    width: $width;
    font-size: 32px;
    div:nth-child(2) {
      background: #cbe8ff;
      width: $width;
      height: 11px;
      border-radius: 5px;
      margin: -5px 0 0 0;
    }
  }
}
.database {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  .searchBox {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .input-search {
      width: 487px;
      height: 30px;
      border-radius: 5px;
    }
    .button-search {
      background-color: #fff;
    }
  }
  .databaseBox {
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50vh;
    .searchNone {
      margin: 60px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #dcdfe6;
      img {
        width: 20vh;
        height: 20vh;
      }
      span {
        color: #a1a1a1;
        font-size: 14px;
      }
    }
    .interest {
      margin: 40px 0 10px;
    }
    @include interest(400px);
  }
}
</style>
